import React from "react"
import PropTypes from "prop-types"

const MasonryLayout = ({ columns, gap, children, className }) => {
  const items = children.filter(item => item !== false && item.props.isVisible)
  const columnWrapper = {}
  const result = []

  for (let i = 0; i < columns; i++) {
    columnWrapper[`column${i}`] = []
  }

  items.map((item, index) => {
    const columnIndex = Math.floor(index / (items.length / columns))
    if (item) {
      columnWrapper[`column${columnIndex}`].push(
        <div style={{ marginBottom: `${gap}px` }} data-key={item.key}>
          {item}
        </div>
      )
      return true
    }
    return false
  })

  for (let i = 0; i < columns; i++) {
    result.push(
      <div
        style={{
          paddingLeft: `${i > 0 ? gap : 0}px`,
        }}
        className={`w-1/2 lg:w-1/${columns}`}
      >
        {columnWrapper[`column${i}`]}
      </div>
    )
  }

  return <div className={`${className} flex flex-wrap`}>{result}</div>
}

MasonryLayout.propTypes = {
  columns: PropTypes.number.isRequired,
  gap: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
}

MasonryLayout.defaultProps = {
  columns: 3,
  gap: 15,
}

export default MasonryLayout

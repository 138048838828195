import React from "react"
import { Link } from "gatsby"

const Breadcrumbs = props => {
  return (
    <div className={`relative xl:px-16 px-6 z-30 ${props.className}`}>
      <ul className={`list-none flex flex-row flex-wrap text-xs xl:mx-2`}>
        <li>
          <Link
            className={`hover:text-gold duration-500 ${
              props.theme === "light" ? "text-darkgrey" : "text-white"
            }`}
            to="/"
          >
            Home
          </Link>
        </li>
        {props.data.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.href &&
              item.href !== "/advice" &&
              item.href !== "/inspiration" ? (
                <li
                  className={`ml-1 hover:text-gold duration-500 ${
                    item.active ? "font-bold text-gold" : "text-darkgrey"
                  } ${props.theme === "light" ? "" : "text-white"}`}
                >
                  {" "}
                  <span
                    className={
                      props.theme === "light" ? "text-darkgrey" : "text-white"
                    }
                  >
                    —
                  </span>{" "}
                  <Link
                    className={`${props.theme === "light" ? "" : "text-white"}`}
                    to={`${item.href}/`}
                  >
                    {item.label}
                  </Link>
                </li>
              ) : (
                <li
                  className={`ml-1 ${
                    item.active ? "font-bold text-gold" : "text-darkgrey"
                  } ${props.theme === "light" ? "" : "text-white"}`}
                >
                  {" "}
                  <span
                    className={
                      props.theme === "light" ? "text-darkgrey" : "text-white"
                    }
                  >
                    —
                  </span>{" "}
                  <span
                    className={`${props.theme === "light" ? "" : "text-white"}`}
                  >
                    {item.label}
                  </span>
                </li>
              )}
            </React.Fragment>
          )
        })}
      </ul>
    </div>
  )
}

export default Breadcrumbs
